import React, { ReactElement } from "react";
import styled from "@emotion/styled";
import { Navbar, Container, Nav, Button } from "react-bootstrap";

const NavbarWrapper = styled(Navbar)`
  padding: 1rem 0;
  border: solid 0.5px #f2f2f2;
`;

const StyledButton = styled(Button)`
  border-radius: 100rem;
  color: #29558c;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin-right: 2rem;
  border-style: solid;
  border-width: 0.5px;
  border-image-source: linear-gradient(to bottom, #ffa66d, #f8528d);
  border-image-slice: 1;
  background-image: linear-gradient(to bottom, #ffffff, #ffffff),
    linear-gradient(to bottom, #ffa66d, #f8528d);
  background-origin: border-box;
  background-clip: content-box, border-box;
  border: solid 1px transparent;
  box-shadow: 2px 1000px 1px #fff inset;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  &:hover {
    color: black;
  }
`;

const Navigation = (): ReactElement => {
  return (
    <Container>
      <div style={{ backgroundColor: "#fff9f5", height: "0.5rem" }}></div>
      <NavbarWrapper variant="light" fixed="top" sticky="top" expand="lg">
        <Navbar.Brand href="#home" className="App-logo"></Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ml-auto" activeKey="/">
            <StyledButton>Log in</StyledButton>
          </Nav>
          <Nav>
            <StyledButton>Sign up</StyledButton>
          </Nav>
        </Navbar.Collapse>
      </NavbarWrapper>
    </Container>
  );
};

export default Navigation;
