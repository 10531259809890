import React, { ReactElement } from "react";
import Container from "react-bootstrap/Container";
import { Row, Col } from "react-bootstrap";
import styled from "@emotion/styled";

const ContentRow = styled(Row)`
  margin: 5rem 0;
  margin-bottom: 0;
`;

const Title = styled.h5`
  margin-bottom: 2rem;
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  color: #3a3a3a;
`;

const P = styled.p`
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  margin-left: 5rem;
  margin-right: 5rem;
  text-align: center;
  color: #aab3c2;
`;

const FooterBlock = styled.footer`
  height: 60px;
  bottom: 0;
  width: 100%;
  background-color: #d55f2c;
`;

const FooterContainerDiv = styled.div`
  margin-top: 10rem;
  margin-bottom: 1rem;
  border-top: 0.5px #e0e0e0 solid;
`;

const Footer = (): ReactElement => (
  <>
    <FooterContainerDiv>
      <Container>
        <ContentRow noGutters>
          <Col>
            <Title>Sendcash 2020</Title>
            <P>Sendcash is powered by Buycoins and built on the Buycoins API.</P>
            <P>Transfers to Nigeria are processed by Paystack.</P>
          </Col>
        </ContentRow>
      </Container>
    </FooterContainerDiv>
    <FooterBlock></FooterBlock>
  </>
);

export default Footer;
